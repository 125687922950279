import { Col, Flex, Image, Row, Typography } from 'antd'
import React from 'react'

const Brands = () => {
  return (
    <Flex justify='center' className='bg-dark'>
        <div className='w-85 py-3'>
            <Row gutter={[16,16]} align={'middle'}>
                <Col xs={24} sm={24} md={6}>
                    <Typography.Title level={2} className='text-white'>
                        Our Business Partners
                    </Typography.Title>
                </Col>
                <Col xs={12} sm={12} md={3}>
                    <Image src='/assets/brands/b-1.jpg' preview={false} className='rounded-sm' />
                </Col>
                <Col xs={12} sm={12} md={3}>
                    <Image src='/assets/brands/b-2.jpg' preview={false} className='rounded-sm' />
                </Col>
                <Col xs={12} sm={12} md={3}>
                    <Image src='/assets/brands/b-3.webp' preview={false} className='rounded-sm' />
                </Col>
                <Col xs={12} sm={12} md={3}>
                    <Image src='/assets/brands/b-4.webp' preview={false} className='rounded-sm' />
                </Col>
                <Col xs={12} sm={12} md={3}>
                    <Image src='/assets/brands/b-5.jpg' preview={false} className='rounded-sm' />
                </Col>
                <Col xs={12} sm={12} md={3}>
                    <Image src='/assets/brands/b-6.webp' preview={false} className='rounded-sm' />
                </Col>
            </Row>
        </div>
    </Flex>
  )
}

export {Brands}