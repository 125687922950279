import React from "react";
import RouterF from "./RouterF";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, theme } from 'antd';
import { SmFooter } from "./components";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#C93438',
              borderCardrad:20,
              cardshadow:'0px 4px 25px 0px #D9D9D9',
              mainColor: '#C93438',
              secondarColor:'#000',
              colorgray: '#999696',
              bgtransparent:'transparent',
              fonth:'13px',
            }
          }}
        >
          <RouterF theme={theme.components} />
        </ConfigProvider>
        <SmFooter />
      </BrowserRouter>
    </div>
  );
}

export default App;
