import React from 'react'
import { Routes, Route } from "react-router-dom";
import { HomePage } from '../pages';

const RouterF = () => {
  return (
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
  )
}

export default RouterF