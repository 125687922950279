import React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Flex, Rate, Tooltip, Typography } from 'antd'

const RateReview = ({ textColor = 'white' }) => {


    const data = [
        {
          title:'V1',
          icon:<UserOutlined />,
        },
        {
          title:'V2',
          icon:<UserOutlined />,
        },
        {
          title:'V3',
          icon:<UserOutlined />,
        },
        {
          title:'V4',
          icon:<UserOutlined />,
        },
        {
          title:'V1',
          icon:<UserOutlined />,
        },
        {
          title:'V2',
          icon:<UserOutlined />,
        },
        {
          title:'V3',
          icon:<UserOutlined />,
        },
        {
          title:'V4',
          icon:<UserOutlined />,
        },
      ]

  return (
    <Flex gap={'large'}>
        <Avatar.Group
            maxCount={5}
            maxStyle={{
            color: '#f56a00',
            backgroundColor: '#fde3cf',
            }}
        >
            {
            data?.map((data,d)=>
                <Tooltip title={data?.title} placement="top" key={d}>
                <Avatar
                style={{
                    backgroundColor: '#f56a00'
                }}
                    icon={data?.icon}
                />
                </Tooltip>
            )
            }
        </Avatar.Group>
        <Flex vertical>
          <Rate defaultValue={5} />
          <Typography.Text style={{ color: textColor }}>
            from 2763 reviews
          </Typography.Text>
        </Flex>
    </Flex>
  )
}

export {RateReview}