import React from 'react'
import { Button, Flex, Typography } from 'antd'
import { TopSection } from '../TopSection'
import './index.css'
import { RateReview } from '../RateReview'


const BannerVideo = () => {

  return (
    <div className='banner'>
        <div className='overlay'></div>
        <TopSection />
        <Flex justify='center'>
          <div className='w-85'>
            <Flex gap={30} vertical justify='center' align='center'>
              <Typography.Title level={1} className='top-title text-white m-0 text-center'>
                  Repla – Innovative and Adaptable Digital Solutions for Modern Business
              </Typography.Title>
              <Typography.Title level={5} className='text-white m-0'>
                  Leading IT company that specializes in delivering top-quality services to clients across different industries.
              </Typography.Title>
              <iframe width="90%" height="500" src="https://www.youtube.com/embed/jbbXIHixnfY?si=b4yuV0-xEl4ZOGyS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <Button type='primary' className='btncust'>
                Learn More
              </Button>
              <RateReview textColor='white' />
            </Flex>
          </div>
        </Flex>
    </div>
  )
}

export {BannerVideo}