import React from 'react'
import { Col, Row, Typography, Flex } from 'antd'
import { FacebookFilled, TwitterSquareFilled, InstagramFilled } from '@ant-design/icons';
import './index.css'

const SmFooter = () => {
  return (
    <Flex justify='center'  className='py-3 bg-dark'>
        <div className='w-85'>
            <Row gutter={[24,24]} align={'middle'}>
                <Col lg={{span:12}} md={{span:12}} xs={{span: 24}} sm={{span:24}}>
                    <Typography.Title className='m-0 text-white text-mbl-center' level={5}>
                        Developed by Repla Security & Technology
                    </Typography.Title>
                </Col>
                <Col lg={{span:12}} md={{span:12}} xs={{span: 24}} sm={{span:24}}>
                    <Flex justify='end' gap={'small'}>
                        <FacebookFilled 
                          className='footer-icons' 
                          onClick={()=>{window.location.href='https://replatechnologies.com'}}
                        />
                        <TwitterSquareFilled 
                          className='footer-icons' 
                          onClick={()=>{window.location.href='https://replatechnologies.com'}}
                        />
                        <InstagramFilled 
                          className='footer-icons'
                          onClick={()=>{window.location.href='https://replatechnologies.com'}}
                        />
                    </Flex>
                </Col>
            </Row>
        </div>
    </Flex>
  )
}

export {SmFooter}