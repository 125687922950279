import React from 'react'
import { Button, Card, Col, Flex, Rate, Row, Typography } from 'antd'
import './index.css'
import { RateReview } from '../MainBanner/RateReview/index'

const Reviews = () => {

    const data = [
        {
            title:'Jaleel Ahmad',
            desc:`If you're looking for app, web or software development or any IT services, you're on right place.
            I had a great experience with these guys. Very professional and experienced team. And the best thing is quality work within budget.`,
        },
        {
            title:'Amer Alhafid',
            desc:`Genius programmers, and honest people, It was a really nice experience with them`,
        },
        {
            title:'Hassan Tariq',
            desc:`One of the best company I worked with`,
        },
        {
            title:'Muhammad Azeem',
            desc:`At Repla Security and Technology, they specialize in creating tailored solutions for each client. Their passion for innovation drives them to continually explore new ideas and seamlessly integrate the latest technologies. What truly makes them stand out is their unwavering commitment to ensuring customer satisfaction.
            `,
        },
        {
            title:'Shujat Ali',
            desc:`Having worked at Repla Technologies, I've witnessed firsthand their dedication to pushing boundaries and delivering transformative solutions that redefine industry standards.`,
        },
        {
            title:'Rasheed Ahmed',
            desc:'Outstanding productivity and excellent delivery. i am very impressed their vocal understanding.',
        },
    ]

  return (
    <div className='bg'>
        <Flex justify='center'>
            <div className='w-85'>
                <Row gutter={[16,16]} justify={'center'}>
                    <Col span={24}>
                        <div className='upperline'></div>
                        <Typography.Title level={1} className='top-title py-3 text-white text-center'>
                            Reviews
                        </Typography.Title>
                    </Col>
                    {
                        data?.map((data,d)=>
                            <Col xs={24} sm={24} md={12} lg={8} key={d}>
                                <Card className='h-100'>
                                    <div>
                                        <img src="/assets/rev.jpg" width={60} height={60} style={{borderRadius:'50%'}} alt="" />
                                    </div>
                                    <Flex vertical gap={10}>
                                        <Typography.Title level={5} className='m-0'>
                                            {
                                                data?.title
                                            }
                                        </Typography.Title>
                                        <Rate defaultValue={5} />
                                        <Typography.Text>
                                            {
                                                data?.desc
                                            }
                                        </Typography.Text>
                                    </Flex>
                                </Card>
                            </Col>
                        )
                    }
                    <Col span={24}>
                        <Flex justify='center' align='center' vertical gap={'large'} className='py-3'>
                            <Button type='primary' className='btncust-sm'>
                                More Reviews
                            </Button>
                            <RateReview />
                        </Flex>
                    </Col>
                </Row>
            </div>
        </Flex>
    </div>
  )
}

export {Reviews}