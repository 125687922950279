import React from 'react'
import { BannerVideo, Brands, Contactus, IntroductionCompany, PortFolio, Reviews, Services } from '../../components'

const HomePage = () => {
  return (
    <div>
      <BannerVideo />
      <Brands />
      <IntroductionCompany />      
      <Services />      
      <PortFolio />
      <Reviews />
      <Contactus />
    </div>
  )
}

export {HomePage}