export const dataVideo = [
    {
        video:'video-1.mp4'
    },
    {
        video:'video-2.mp4'
    },
    {
        video:'video-3.mp4'
    },
    {
        video:'video-4.mp4'
    },
    {
        video:'video-5.mp4'
    },
    {
        video:'video-6.mp4'
    },
    {
        video:'video-7.mp4'
    },
    {
        video:'video-8.mp4'
    },
    {
        video:'video-ap-1.mp4'
    },
    {
        video:'video-ap-2.mp4'
    },
    {
        video:'video-ap-3.mp4'
    },
    {
        video:'video-ap-4.mp4'
    },
]