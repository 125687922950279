import React from 'react'
import { Col, Form, Row, Space, Typography, Input, Button, Card, Flex } from 'antd'
import { Link } from 'react-router-dom'
import './index.css'

const Contactus = () => {

    const data = [
        {
            count: '1',
            desc:'Having received and processed your request, we will get back to you shortly to detail your project needs and sign an NDA to ensure the confidentiality of information.'
        },
        {
            count: '2',
            desc:' After examining requirements, our analysts and developers devise a project proposal with the scope of works, team size, time and cost estimates.'
        },
        {
            count: '3',
            desc:'We arrange a meeting with you to discuss the offer and come to an agreement.'
        },
        {
            count: '4',
            desc:'We sign a contract and start working on your project as quickly as possible.'
        },
    ]
  return (  
    <Flex justify='center' className='padding'>
        <div className='w-85'>
            <Row gutter={[64,24]} style={{justifyContent:'space-between'}}>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={60}>
                        <Space direction='vertical' size={0}>
                            <Typography.Title level={1} className='top-title'>
                                Contact us!
                            </Typography.Title>
                            <Typography.Title level={5}>
                                <Link to={'/'} className='brand-color f-weight-normal'>Book a call</Link> or fill out the form below and we’ll get back to you once we’ve processed your request.
                            </Typography.Title>
                        </Space>
                        <Form layout="vertical"
                            >
                                <Row gutter={[16,16]}>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="name" 
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your name',
                                            },
                                            ]}
                                        >
                                            <Input 
                                                placeholder="Enter your name" 
                                                className='border-0-bottom'
                                                value='' 
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your email',
                                            },
                                            ]}
                                        >
                                        <Input 
                                            placeholder="Enter email" 
                                            className='border-0-bottom'
                                            value=''
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="subject" 
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your subject',
                                            },
                                            ]}
                                        >
                                            <Input 
                                                placeholder="Enter subject" 
                                                className='border-0-bottom'
                                                value='' 
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="phone" 
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your phone no',
                                            },
                                            ]}
                                        >
                                            <Input 
                                                placeholder="Enter phone no" 
                                                className='border-0-bottom'
                                                value='' 
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="message"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your message',
                                            },
                                            ]}
                                        >
                                        <Input.TextArea
                                            rows={4}
                                            size='large'
                                            placeholder="Write message here..." 
                                            className='border-0-bottom'
                                            value='' 
                                            />
                                        </Form.Item>
                                        <Typography.Text className='gray-sm-text'>
                                            Please include project details, duration, tech stack, IT professionals needed, and other relevant info
                                        </Typography.Text>
                                    </Col>
                                    <Col lg={{span: 6}} md={{span: 8}} xs={{span: 24}} sm={{span: 24}}>
                                        <Flex>
                                            <Button 
                                                htmlType='submit' 
                                                type='primary'
                                                className='btncust-sm'
                                            >
                                                Send
                                            </Button>
                                        </Flex>
                                    </Col>
                                </Row>
                        </Form>
                    </Space>
                </Col>
                <Col lg={{span: 11}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Card className='card-block'>
                        <Space size={40} direction='vertical'>
                            <Typography.Title level={1} className='mt-5'>What happens next?</Typography.Title>
                            <div className='list-circle'>
                                <ul>
                                    {
                                        data?.map((datlst,ds)=>
                                        <li key={ds}>
                                            <div className='circle-count'>{datlst?.count}</div>
                                            {
                                                datlst?.desc
                                            }
                                        </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </div>
    </Flex>
  )
}

export {Contactus}