import React from 'react'
import { Flex, Image } from 'antd'
import { NavLink } from 'react-router-dom'
import './index.css'

const TopSection = () => {
  return (
    <div className='postion-top'>
      <Flex className='alignment' align='center'>
        <NavLink to='/'>
          <Image src='/assets/logo.png' width={100} preview={false} />
        </NavLink>
        <Image src='/assets/toprate.png' width={200} preview={false} className='hide'/>
    </Flex>
    </div>
  )
}

export {TopSection}