import React from 'react'
import { Row, Col, Flex, Card, Typography } from 'antd'
import './index.css'

const Services = () => {

    const data = [
        {
            icons:'software-dev.png',
            title:'Software Developement',
            desc:'At Repla, we specialize in full-stack development, web design, mobile app development, and digital marketing services to help businesses succeed in the digital world.'
        },
        {
            icons:'website-dev.png',
            title:'Website Developement',
            desc:'With Repla, businesses can rely on our expertise to create innovative and intuitive websites that ensure product quality and customer satisfaction, resulting in a high conversion rate.'
        },
        {
            icons:'app-dev.png',
            title:'App Developement',
            desc:'When it comes to mobile app development, we build iOS and Android applications that efficiently complement web solutions.'
        },
        {
            icons:'game-dev.png',
            title:'Game Developement',
            desc:'Repla provides game development services which includes building iOS, Android and Windows games.'
        },
        {
            icons:'blockchain.png',
            title:'Blockchain',
            desc:'Repla provides Blockchain development services which includes banking, marketing, industry applications.'
        },
        {
            icons:'ux-design.png',
            title:'UI / UX Design',
            desc:'Repla’s innovative approach to UI/UX Design ensures customer satisfaction as they are meant for developing visually attractive, efficient digital interfaces that attracts customers.'
        },
        {
            icons:'ai.png',
            title:'Artificial Intelligence',
            desc:'We offer cutting-edge AI services to empower businesses with intelligent solutions. Our AI expertise enables organizations to leverage data-driven insights, automate processes, and enhance decision-making capabilities'
        },
        {
            icons:'seo.png',
            title:'Search Engine Optimization',
            desc:'Repla has the most advanced testing platform in SEO and build calibrated search engine models against any existing search engine to ensure websites rank high on Google.'
        },
    ]
  return (
    <div className='bg'>
        <Flex justify='center'>
            <div className='w-85'>
                <Row gutter={[24,24]}>
                    <Col span={24}>
                        <div className='upperline'></div>
                        <Typography.Title level={1} className='text-center py-3 text-white top-title'>
                            Our Services
                        </Typography.Title>
                    </Col>
                    {
                        data?.map((service,index)=>
                            <Col key={index} xs={24} sm={12} md={12} lg={6}>
                                <Card className='service-card'>
                                    <div className='card-image'>
                                        <img src={"/assets/icons/"+service?.icons} width={60} height={60} alt="" />
                                    </div>
                                    <Typography.Title level={5} className='brand-text'>
                                        <em>
                                        {service?.title}
                                        </em>
                                    </Typography.Title>
                                    <Typography.Text>
                                        {service?.desc}
                                    </Typography.Text>
                                </Card>
                            </Col>
                        )
                    }
                        
                </Row>
            </div>
        </Flex>
    </div>
  )
}

export {Services}