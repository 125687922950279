import React from 'react'
import { Row, Col, Flex, Typography, Image } from 'antd'
import './index.css'
import { dataVideo } from './dataVideo'

const PortFolio = () => {
  return (
    <div>
        <Image src='/assets/layer3.png' width={'100%'} preview={false} style={{marginTop:'-14px'}} />
        <Flex justify='center' className='padding'>
            <div className='w-85'>
                <Row gutter={[24,24]}>
                    <Col span={24}>
                        <div className='upperline'></div>
                        <Typography.Title level={1} className='text-center top-title'>
                            Our Portfolio
                        </Typography.Title>
                    </Col>
                    {
                        dataVideo?.map((datavid,dv)=>
                            <Col lg={{span: 6}} md={{span: 8}} xs={{span: 12}} sm={{span: 12}}>
                                <div className="video">
                                    <video 
                                        key={dv}
                                        className='w-100 my-0'
                                        autoplay="autoplay" 
                                        loop="loop" 
                                        muted="muted" >
                                        <source 
                                            src={'assets/videos/'+datavid?.video} 
                                            type="video/mp4" />
                                    </video>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </div>
        </Flex>
        <Image src='/assets/layer2.png' width={'100%'} preview={false} />
    </div>
  )
}

export {PortFolio}