import React from 'react'
import { Flex, Row, Col, Image, Typography, List, Button } from 'antd'
import { RateReview } from '../MainBanner/RateReview'

const IntroductionCompany = () => {

    const data = [
        {
            title: '4 Modules',
        },
        {
            title: '7 Bonuses',
        },
        {
            title: 'Hands-On Training',
        },
        {
            title: '2 Assignments',
        },
        {
            title: 'Secret Client Hunting Strategies',
        },
        {
            title: 'Direct Support',
        },
      ];
  return (
    <Flex justify='center' className='padding'>
        <div className='w-85'>
            <Row gutter={[24,24]} align={'middle'} className='mbl-convert'>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Flex vertical gap={20}>
                        <Typography.Title level={1} className='m-0 font-title'>
                            Company Introduction
                        </Typography.Title>
                        <Typography.Text>
                            Repla – Innovative and Adaptable Digital Solutions for Modern Business
                        </Typography.Text>
                        <Typography.Text>
                            Repla is a leading IT company that specializes in delivering top-quality services to clients across different industries. Our extensive experience in diverse sectors enables us to offer valuable insights and innovative solutions that guarantee customer satisfaction.
                        </Typography.Text>
                        <Typography.Text>
                            At Repla we specialize in providing innovative and adaptable digital solutions that empower businesses to compete in today’s fast-paced market and unlock new opportunities for growth.
                        </Typography.Text>
                        {/* <List
                            itemLayout="horizontal"
                            dataSource={data}
                            bordered={false}
                            renderItem={(item, index) => (
                            <List.Item style={{padding:"0px",border:'0px'}} key={index}>
                                <Flex align='center' gap={'small'}>
                                    <Image src='/assets/icons/check.png' width={'15px'} preview={false} />
                                    <Typography.Text strong>
                                        {
                                            item?.title
                                        }
                                    </Typography.Text>
                                </Flex>
                            </List.Item>
                            )}
                        /> */}
                        <Flex>
                            <Button type='primary' className='btncust-sm'>
                                Learn More
                            </Button>
                        </Flex>
                    </Flex>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='text-center'>
                        <Image src='/assets/aboutus.webp' width={'80%'} style={{borderRadius:12}} preview={false} />
                    </div>
                </Col>
            </Row>
        </div>
    </Flex>
  )
}

export {IntroductionCompany}